.endprogram {
  width: auto;
  text-align: center;
  background-size: cover;
  height: auto;
}

.title_program {
  font-size: calc(1.5rem + 2vw);
  color: tomato;
  font-weight: 600;
  padding: 1.5rem 0;
  margin-bottom: 1rem;
  padding-top: 40px;
}

.endhead {
  color: white;
  font-weight: 600;
}.enddet {
  padding-top: 20px;
  color: white;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}


.grid>article {
  border: 2px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgb(250, 248, 248);
  
}
.grid>article:hover{
  background-color: rgb(44, 33, 33);
}

.grid>article img {
  max-width: 100%;
}

.grid .text {
  padding: 20px;
}