.promote{
    padding-top: 60px;
    overflow: hidden;
}
.ptag{
    color: black;
}

.title{
        font-size: calc(1.5rem + 2vw);
        text-align: center;
        color: tomato;
        font-weight: 600;
        padding: 1.5rem 0;
        margin-bottom: 1rem;
}
.poster{
    font-size: calc(1rem + 1.5vw);
        text-align: center;
        color: black;
        font-weight: 600;
        padding: 1.5rem 0;
        margin-bottom: 1rem;
        ;
}

.register-box {
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
}
.register-pa {
    padding-top: 100px;
}
.zindex {
    position: relative;
    z-index: 999;
}
.tweets-container {
    margin: auto;
    max-width: 600px;
  }
 
  
  .tweets-container > .tweet:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
 