.about{
    padding-top: 20px;
    width: auto;
    background-color: #222;
    background-size: cover;
    overflow: hidden;
}

.p{
    font-size: 16px;
    color: white;
}
.about_span{
    color: white;
}
.span2{
    color: tomato;
}

.para{
    font-size: 1.2rem;
    padding-bottom: 0.3rem;
    color: white;
    padding-top: 30px;
}

.title_about{
        font-size: calc(1.5rem + 2vw);
       
        color: tomato;
        font-weight: 600;
        padding: 1.5rem 0;
        margin-bottom: 1rem;
        padding-top: 40px   ;
}


.wrapper {
    padding: 5vh 1rem;
    min-height: 80vh;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.35) 20%,
      rgba(0, 0, 0, 0.85)
    );
  }

  .p {
    color: white;
    font-size: 14px;
    max-width: 600px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }
  
  .h4 {
    text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.5);
    font-size: calc(1rem + 0.5vw);
  }
  .headpara {
    font-weight: 900;
    color: white;
    font-size: calc(1.5rem + 1.5vw);
    text-transform: uppercase;
    text-shadow: 2px 3px 4px rgba(250, 248, 248, 0.5);
  }